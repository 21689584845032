

<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="工艺路线名称">
					<c-input name="name"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_route_edit')" color="sys" @click="createRoute">新建工艺路线</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="路线编码"
					width="100"
					name="id"
				></c-table-column>

				<c-table-column
					label="路线名称"
					width="260"
					name="name"
				></c-table-column>

				<c-table-column
					label="工序数量"
					width="70"
					name="process_count"
				></c-table-column>
				
				<c-table-column
					label="说明"
					width="150"
					name="remark"
				></c-table-column>

				<c-table-column
					label="创建人"
					width="100"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="180"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_route_edit')" @click="editRoute(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_route_edit')" @click="deleteRoute(data)">删除</c-table-button>
						<c-table-button v-if="auth('b_route_view')" @click="processesList(data)">工序配置</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--新增、编辑 弹框-->
		<c-dialog ref="customerEdit" :title="editState ? '编辑工艺路线' : '新建工艺路线'" width="500" @resolve="submitRoute">
			<c-form ref="customerForm" unit-width="100%">
				<c-form-item label="工艺路线名称" required>
					<c-input name="name" maxlength="200"></c-input>
				</c-form-item>
				<c-form-item label="备注说明">
					<c-input type="textarea" name="remark"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

		<!--工序配置列表 -->
		<c-dialog ref="processesDialog" :title="this.name+' - 已配置工序'" width="800">
			<m-search ref="processesSearch" @search="searchProcessesHandle">
				<m-search-item label="工序名称">
					<c-input name="name"></c-input>
				</m-search-item>
				<m-search-item label="工序说明">
					<c-input name="remark"></c-input>
				</m-search-item>
			</m-search>
			<m-operate>
				<c-button v-if="auth('b_route_edit')" color="sys" @click="addProcesses">新增工序</c-button>
			</m-operate>
			<c-table ref="processesTable" height="400" :paging="false">
				<c-table-column
					label="工序编码"
					width="70"
					name="processes_id"
				></c-table-column>
				
				<c-table-column
					label="工序名称"
					width="150"
					name="name"
				></c-table-column>
				
				<c-table-column
					label="工序单价"
					width="80"
					name="price"
				>
					<template v-slot="{data}">
						{{data.price}}
						<a @click="updateProcessesPrice(data)" style="color:blue">修改</a>
					</template>
				</c-table-column>
				
				<c-table-column
					  type="sort"
					  label="排序"
					  power="b_route_edit"
					  @sort="processesSortHandle"
				></c-table-column>
				
				<c-table-column
					label="工序说明"
					width="200"
					name="processes_remark"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="80"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_route_edit')" @click="deleteProcesses(data)">取消</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</c-dialog>
		
		<!--工序单价修改 弹框-->
		<c-dialog ref="processesPriceEdit" title="编辑工序单价" width="400" @resolve="submitProcessesPrice">
			<c-form ref="processesPriceForm" unit-width="100%">
				<c-form-item label="工序单价" required>
					<c-input name="price" type="number" decimal="5" range="0,99999" unit="元"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>
		
		<!--工序弹框-->
		<processes-selector ref="processesSelector" multiple @resolve="submitProcessesConfig"></processes-selector>

  </page>
</template>

<script>
import processesSelector from '@/components/service/processes_selector.vue'
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_route',
	components: {
		processesSelector
	},
	data() {
		return {
			editState: false,
			name: ''
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/process_route/list',
				data
			});
		},
		//新建工艺路线
		createRoute() {
			this.editState = false;
			this.$refs.customerForm.clear();
			this.$refs.customerEdit.open();
		},
		//编辑工艺路线
		editRoute(val) {
			this.editState = true;
			this.$refs.customerForm.set(val);
			this.$refs.customerEdit.open();
		},
		//删除工艺路线
		deleteRoute(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/process_route/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitRoute(stop) {
			stop();
			this.$refs.customerForm.submit({
				url: this.editState ? '/process_route/edit' : '/process_route/add',
				rule: {
					name: '请填写工艺路线名称'
				},
				dataFormatter: data => {
					data.type = 1;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.customerEdit.close();
				}
			});
		},
		//查看关联工序
		processesList(val){
			this.$refs.processesDialog.open();
			this.route_id = val.id;
			this.name = val.name;
			this.$refs.processesSearch.search();
		},
		//搜索已关联工序
		searchProcessesHandle(data){
			data.type = 1;
			data.route_id = this.route_id;
			this.$refs.processesTable.load({
				url: '/process_route/processes_list',
				data
			});
		},
		//新增工序关联-弹框
		addProcesses(){
			this.$refs.processesSelector.openExists(this.route_id);
		},
		//新增工序关联-确定
		submitProcessesConfig(data){
			this.request({
				url: '/process_route/set_processes',
				data: {
					route_id: this.route_id,
					processes_ids: Array.from(data, item => {
						return {
							processes_id: item.id
						}
					})
				},
				loading: true,
				success: () => {
					this.$refs.processesTable.update();
					this.$refs.table.update();
				}
			});
		},
		//取消工序关联
		deleteProcesses(val){
			this.$confirm({
				title: '确认',
				message: '确定要取消工序【'+val.name+'】的关联吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/process_route/delete_processes',
						data: {
							route_id: this.route_id,
							ref_id: val.id
						},
						loading: true,
						success: data => {
							this.$message({
							  message: '取消成功',
							  type: 'success'
							});
							this.$refs.processesTable.update();
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//排序
		processesSortHandle(row, type, next) {
			//row.prev() 上一行数据
			//row.next() 下一行数据
			let submitData ={};
			submitData.type = 1
			if(type=='top' || type=='bottom'){
				submitData.sort_type = type;
				submitData.id = row.data.id;
			}
			if(type=='up'){
				submitData.id1 = row.data.id;
				submitData.id2 = row.prev().data.id;
			}
			if(type=='down'){
				submitData.id1 = row.data.id;
				submitData.id2 = row.next().data.id;
			}
			this.request({
				url: '/process_route/processes/sort',
				data: submitData,
				loading: true,
				success: data => {
					next();
				}
			});
		},
		//修改工艺单价-弹框
		updateProcessesPrice(val){
			this.$refs.processesPriceForm.clear();
			this.$refs.processesPriceForm.set(val);
			this.$refs.processesPriceEdit.open();
		},
		//修改工艺单价-提交保存
		submitProcessesPrice(stop) {
			stop();
			this.$refs.processesPriceForm.submit({
				url: '/process_route/processes/update_price',
				rule: {
					price: '请填写工序单价'
				},
				dataFormatter: data => {
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '修改工序单价成功',
						type: 'success'
					});
					this.$refs.processesTable.update();
					this.$refs.processesPriceEdit.close();
				}
			});
		}
  }
}
</script>