<template>
	<c-dialog ref="dialog" title="选择工序" width="650" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="工序名称">
				<c-input name="name"></c-input>
			</m-search-item>
			<m-search-item label="工序说明">
				<c-input name="remark"></c-input>
			</m-search-item>
		</m-search>
		<c-table ref="table" height="400" >
			<c-table-column
				type="selector"
				:multiple="!!multiple"
			></c-table-column>
			
			<c-table-column
				label="工序编码"
				width="70"
				name="id"
			></c-table-column>
			
			<c-table-column
				label="工序名称"
				width="150"
				name="name"
			></c-table-column>
			
			<c-table-column
				label="用工单价"
				width="70"
				name="price"
			></c-table-column>
			
			<c-table-column
				label="工序说明"
				width="300"
				name="remark"
			></c-table-column>
			
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		data() {
			return {
				route_id: '',
				not_route_id: ''
			}
		},
		methods: {
			//普通工序弹框
			open(route_id) {
				this.$refs.search.clear();
				this.$refs.dialog.open();
				this.route_id = route_id;
				this.searchHandle();
			},
			//排除工艺路线选择工序弹框
			openExists(not_route_id) {
				this.$refs.search.clear();
				this.$refs.dialog.open();
				this.not_route_id = not_route_id;
				this.searchHandle();
			},
			
			//搜索工序
			searchHandle(data){
				if (!data){
					data = {};
				}
				data.route_id = this.route_id;
				data.not_route_id = this.not_route_id;
				this.$refs.table.load({
					url: '/dic/processes/list',
					data
				});
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择工序'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>